import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "ninua_paok" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Компания Catenaccio Sports Agency совместно с Леваном Габричидзе, официальным агентом футболиста Николоза Нинуа (21 год, капитан «Динамо» Тбилиси), в ходе долгих переговоров успешно достигли договорённости со всеми действующими сторонами и организовали переход Николоза Нинуа из «Динамо» Тбилиси в ПАОК Салоники.</p>
          <p>Контракт футболиста рассчитан на четыре года.</p>
          <p>Catenaccio Sports Agency желает Николозу скорейшего дебюта в составе ФК ПАОК и национальной сборной Грузии!</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/txe8XBlzaeE"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}